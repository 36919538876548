import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { State } from '../../types';
import { LayoutHeaderState } from '../../store/ducks/app/types';
import { domain, Context } from '../../config';
import PrivatePolicy from "./PrivatePolicy";


export function Header() {
    const { locale } = useContext(Context);
    const history = useHistory();
    const { pathname } = useLocation();
    const header: LayoutHeaderState = useSelector(
        (state: State) => state.layouts.header
    );


    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => {
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
    };


    const modalStyle = {
        position: "fixed",
        zIndex: 1,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        overflow: 'auto',
        backgroundColor: "rgba(0, 0, 0, 0.4)",
    };
    const modalContentStyle = {
        backgroundColor: "#fefefe",
        margin: "15% auto",
        padding: "20px",
        border: "1px solid #888",
        width: "80%",
        maxWidth: "800px",
    };
    const modalClose = {
        color: "#aaa",
        float: "right",
        fontSize: "28px",
        fontWeight: "bold",
        cursor: "pointer",
    };

    return (
        <header
            className={cx(
                'header',
                locale === 'ru' && 'header--ru',
                pathname.indexOf('careers') !== -1 && 'header--black',
                pathname.indexOf('careers') !== -1 && 'header--always-black'
            )}
        >
            <div className="header__container">
                <div className="logo header__logo">
                    <Link to="/" className="logo__img">
                        <img
                            src={`${domain}${header.logo}`}
                            alt="Overbest"
                            className="logo__img"
                        />
                        <img
                            src={`${domain}${header.logo_black}`}
                            alt="Overbest"
                            className="logo__img"
                        />
                    </Link>
                </div>
                {header.menu.length > 0 && (
                    <div className="menu header__menu">
                        <div className="menu__wrap">
                            <ul className="menu__list">
                                {header.menu.map((item, key) => (
                                    <li key={key} className="menu__item">
                                        {locale === 'en' && (
                                            <Link
                                                to={item.Link}
                                                className="menu__link"
                                            >
                                                {item.Title}
                                            </Link>
                                        )}
                                        {locale === 'ru' && (
                                            <a
                                                href={`#${item.Link}`}
                                                className="menu__link"
                                            >
                                                {item.Title}
                                            </a>
                                        )}
                                    </li>
                                ))}
                                {locale === 'ru' && (
                                    <li class="menu__item menu__link" onClick={openModal}>
                                        Политика КПД
                                    </li>
                                )}
                            </ul>
                            <a
                                href={header.button.link}
                                className="menu__btn-mob"
                            >
                                {header.button.title}
                            </a>
                        </div>
                    </div>
                )}
                <a
                    href={header.button.link}
                    className="menu__bt button button--medium"
                >
                    {header.button.title}
                </a>
                <div className="menu__btn-icon"></div>
            </div>

            {locale === 'ru' && (
                <div>
                    {isOpen && (
                    <div style={modalStyle}>
                        <div style={modalContentStyle}>
                        <span style={modalClose} onClick={closeModal}>
                            &times;
                        </span >
                        <PrivatePolicy />
                        </div>
                    </div>
                    )}
                </div>
            )}
        </header>


    );
}
