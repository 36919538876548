import React from 'react';
import { Locales, LocalesObj } from 'types';

export const domain = 'https://api.overbest.hk';
export const apiUrl = `${domain}/api/`;
export const enDomain = 'overbest.hk';
export const ruDomain = 'overbest.ru';

export const protocol = 'https://';

export const token =
    '117375c0c8785d72d8eee108d0883c39040fc8daa625d0423826b7e8f8e908ccdd90dd1a84ba878403f984ea78584a7f7ff4c62feae131611b67f091d3692fe48e44128f1add3e9ebd0a650dcbe8d1e21eb439c7efba8bf41189d932d49ba79d6769fa10e9d5f36a50837e066d3c4cf8aea4b4623682787380219022df2232db';
export let defaultLang: Locales = 'ru';

export const defaultContext: { en: LocalesObj; ru: LocalesObj } = {
    en: {
        locale: 'en',
        siteDomain: protocol + enDomain,
    },
    ru: {
        locale: 'ru',
        siteDomain: protocol + ruDomain,
    },
};
if (typeof window !== 'undefined') {
    if (window.location.host && window.location.host.indexOf(ruDomain) > -1) {
        defaultLang = 'ru';
    }
}
export const Context = React.createContext(defaultContext[defaultLang]);
